<template>
  <div class="secharts animate__animated animate__fadeIn">
    <div class="header">
      <Headers :header_data="[{title:'学情分析'}]" />
    </div>
    <!-- 下拉框，日期选择器 -->
    <main>
      <div class="select">
        <div class="icon">
          <img src="../../../assets/images/student/left.png" alt="">
        </div>
        <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss" style="height:2vw;margin-left:45px;width:400px;padding-left:35px;margin-top:6px" v-model="times" type="datetimerange"
          range-separator="——" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </div>
      <div class="main">
        <div class="left1 box">
          <p class="title"> <span> 我的成绩 </span> </p>
          <div class="images">
            <div class="img1 img">
              <p> <span>累计学习单词时长</span> </p>
              <p> <span>{{ currentData.ljxxsc ? currentData.ljxxsc : '0分' }}</span> </p>
            </div>
            <div class="img2 img">
              <p> <span>学习单词总数</span> </p>
              <p> <span>{{ (currentData.xxdczs ? currentData.xxdczs : 0) + '个' }}</span> </p>
            </div>
            <div class="img3 img">
              <p> <span>整体情况</span> </p>
              <div class="gauge" id="gauge"></div>
            </div>
          </div>
          <!-- 饼图 -->
          <div class="canvas">
            <!-- 饼图1 -->
            <div class="canvas1" id="canvas1"></div>
            <!-- 饼图2 -->
            <div class="canvas2" id="canvas2"></div>
          </div>
        </div>
        <div class="right1 box">
          <p class="title"> <span> 题型得分率分析 </span> </p>
          <!-- 雷达图 -->
          <div class="echartsBox">
            <div class="radar" id="radar"></div>
            <div class="tips">
              <div class="top">
                <p class="blod">错因分析</p>
                <p class="text">{{ cyfx }}</p>
              </div>
              <div class="bottom top">
                <p class="blod">学习小贴士</p>
                <p class="text" v-for="(item, index) in tipContentList" :key="index">{{ index+ 1 + '.' + item }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="left2 box">
          <p class="title"> <span> 学习统计 </span> </p>
          <!-- 柱状图 -->
          <div class="category" id="category"></div>
        </div>
        <div class="right2 box">
          <p class="title"> <span> 进步曲线 ( 每日背单词量 ) </span> </p>
          <!-- 折线图 -->
          <div id="broke" class="broke"></div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>
<script>
import { getTrains, getStudyAnalysis, getStudytips } from '@/api/student'
import * as echarts from 'echarts';
import Vue from "vue";
Vue.use(echarts);
import { BarChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
// 注册必须的组件
echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer])
export default {
  components: {
    Headers: (resolve) => {
      require(['@/components/student/header.vue'], resolve)
    },
    Footer: (resolve) => {
      require(['@/components/student/footer.vue'], resolve)
    }
  },
  data() {
    return {
      tipContentList: [],
      cyfx: '',
      pickerOptions: {
        disabledDate: (item) => {
          return item.getTime() < Date.parse(this.currentJxkssj) || item.getTime() > Date.parse(this.currentJxjssj)
        }
      },
      currentJxkssj: '',
      currentJxjssj: '',
      currentJxid: null, //集训id
      times: [], // 时间段
      currentData: {},
      options: [],
      // 折线图配置
      prokeOption: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          left: '9%',
          realtime: true,
          bottom: -5,
          start: 0,
          end: 50 //初始化滚动条
        }],
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [{
          data: [],
          type: 'line',
          showSymbol: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#b8d0ff' // 0% 处的颜色
              }, {
                offset: 1, color: '#f0f5ff' // 100% 处的颜色
              }],
              globalCoord: false // 缺省为 false
            }
          }
        }],
        grid: {
          show: true,
          top: '4%',
          left: '7%',
          right: '6%',
          bottom: '30%'
        }
      },
      // 柱状图配置
      categoryOption: {
        color: ['#3FA0FF', '#51CC74', '#FAD444',],
        legend: {
          bottom: '13%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let str = params[0].name
            for (let i = 0; i < params.length; i++) {
              let total = (params[i].value.jscsl + params[i].value.shsl + params[i].value.scsl)
              return str + `<br />熟词:${params[i].value.shsl}个(${total > 0 ? (params[i].value.shsl / total * 100).toFixed(2) : 0}%)<br />
                            夹生词:${params[i].value.jscsl}个(${total > 0 ? (params[i].value.jscsl / total * 100).toFixed(2) : 0}%)<br />
                            生词:${params[i].value.scsl}个(${total > 0 ? (params[i].value.scsl / total * 100).toFixed(2) : 0}%)<br />
                            总计:${total}个`
            }
          },
        },
        dataset: {
          dimensions: ['rq', '熟词', '夹生词', '生词'],
          source: []
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          left: '9%',
          realtime: true,
          bottom: -5,
          start: 0,
          end: 50 //初始化滚动条
        }],
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 100,
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}%'
          },
        },
        series: [
          {
            type: 'bar',
            label: { //柱体上显示数值
              show: true,//开启显示
              position: 'top',//在上方显示
              textStyle: {//数值样式
                fontSize: '0.6rem',
                color: '#333'
              },
              formatter: function (val) {
                return val.data.total ? (Math.round(val.data['熟词'] / 100 * val.data.total) + '个') : ''
              },
            }
          },
          {
            type: 'bar',
            label: { //柱体上显示数值
              show: true,//开启显示
              position: 'top',//在上方显示
              textStyle: {//数值样式
                fontSize: '0.6rem',
                color: '#333'
              },
              formatter: function (val) {
                return val.data.total ? (Math.round(val.data['夹生词'] / 100 * val.data.total) + '个') : ''
              },
            }
          },
          {
            type: 'bar',
            label: { //柱体上显示数值
              show: true,//开启显示
              position: 'top',//在上方显示
              textStyle: {//数值样式
                fontSize: '0.6rem',
                color: '#333'
              },
              formatter: function (val) {
                return val.data.total ? (Math.round(val.data['生词'] / 100 * val.data.total) + '个') : ''
              }
            }
          },
        ],
        grid: {
          show: false,
          top: '10%',
          left: '8%',
          right: '5%',
          bottom: '40%'
        }
      },
      // 雷达图配置
      radarOption: {
        radar: {
          indicator: [
            { name: '综合', max: 100 },
            { name: '听音辨英', max: 100 },
            { name: '看英选义', max: 100 },
            { name: '看义拼词', max: 100 },
            { name: '看义选英', max: 100 },
          ],
          name: {
            padding: [-10, -10, -10, -10],
            fontSize: '12',
            color: '#333'
          }
        },
        tooltip: {
          formatter: function (params) {
            return `综合:${params.value[0] ? params.value[0] : 0}%<br />看义选英:${params.value[4] ? params.value[4] : 0}%<br />看义拼词:${params.value[3] ? params.value[3] : 0}%<br /> 看英选义:${params.value[2] ? params.value[2] : 0}%<br /> 听音辨英:${params.value[1] ? params.value[1] : 0}%`
          }
        },
        series: [{
          name: '题型得分率分析',
          type: 'radar',
          areaStyle: {
            color: 'rgba(0, 0, 0, 0)'
          },
          data: [
            {
              value: [],
              name: ''
            }
          ]
        }]
      },
      // 饼图配置
      pieOption1: {
        series: [
          {
            type: 'pie',
            data: [
              { value: 0, name: '熟词', itemStyle: { color: '#3FA0FF' } },
              { value: 0, name: '夹生词', itemStyle: { color: '#51CC74' } },
              { value: 0, name: '生词', itemStyle: { color: '#FAD444' } }
            ],
            label: {
              show: true,
              formatter: '{b}' + '{c}' + '(' + '{d}%' + ')',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              length: 0,
              length2: 10,
              maxSurfaceAngle: ''
            }
          }
        ]
      },
      pieOption2: {
        series: [
          {
            type: 'pie',
            data: [
              { value: 0, name: '未掌握', itemStyle: { color: '#51CC74' } },
              { value: 0, name: '已掌握', itemStyle: { color: '#3FA0FF' } }
            ],
            label: {
              show: true,
              formatter: '{b}' + '{c}' + '(' + '{d}%' + ')'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              length: 0,
              length2: 10,
              maxSurfaceAngle: ''
            }
          }
        ]
      },
      // 仪表盘配置
      gaugeOption: {
        // tooltip:{},
        series: [{
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 1,
          splitNumber: 8,
          radius: '100%', // 大小
          axisLine: {
            lineStyle: {
              width: 6,
              color: [
                [0.26999, '#FDDD60'],
                [0.72999, '#7CFFB2'],
                [1, '#3FA0FF']
              ]
            }
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '20%',
            width: 10,
            offsetCenter: [0, '-60%'],
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            length: 0,
          },
          splitLine: {
            length: 0,
          },
          axisLabel: {
            color: '#464646',
            fontSize: 20,
            distance: -60,
            formatter: function (value) {
            }
          },
          detail: {
            fontSize: 16,
            offsetCenter: [0, '20%'],
            valueAnimation: true,
            formatter: function (value) {
              let str = null
              if (value >= 0.73) {
                str = '优'
              } else if (value <= 0.73 && value >= 0.27) {
                str = '良'
              } else {
                str = '差'
              }
              return str
            },
            color: 'auto'
          },
          data: [{
            value: 0,
          }]
        }]
      }
    }
  },
  created() {
    this.initialization()
  },
  mounted() {
  },
  watch: {
    times(newV, oldV) {
      console.log(newV, oldV,12234344);
      if(newV==null){
        this.initialization()
      }
      // 转换成ios支持的格式，减去8小时时间戳
      if(newV){
         let kssj = new Date(newV[0].replace(' ', 'T') + '.00Z').getTime() - 8 * 60 * 60 * 1000
      let jssj = new Date(newV[1].replace(' ', 'T') + '.00Z').getTime() - 8 * 60 * 60 * 1000
      getStudyAnalysis({ xsid: this.$store.state.userInfo.yhid, jxid: this.currentJxid, kssj, jssj }).then(res => {
        if (res.success) {
          this.currentData = res.result
          this.currentData.listStudy.forEach(ele => {
            ele.total = ele.scsl + ele.jscsl + ele.shsl
            if (ele.scsl || ele.shsl || ele.jscsl) {
              ele['生词'] = Math.floor(ele.scsl / ele.total * 10000) / 100
              ele['熟词'] = Math.floor(ele.shsl / ele.total * 10000) / 100
              ele['夹生词'] = Math.floor(ele.jscsl / ele.total * 10000) / 100
            }
          })
          this.setgauge()
          this.setcategory()
          this.setPieone()
          this.setPietwo()
          this.setbroke()
          this.setradar()
          if (this.currentData.tyxysl + this.currentData.yyhsl + this.currentData.hyysl + this.currentData.kypcsl <= 0) {
            this.cyfx = '暂无错因'
            this.tipContentList = ['加油😄']
          } else if (this.currentData.tyxysl + this.currentData.yyhsl + this.currentData.hyysl + this.currentData.kypcsl === 400) {
            this.cyfx = '暂无错因'
            this.tipContentList = ['继续努力😄']
          } else {
            getStudytips({ hyysl: this.currentData.hyysl, kypcsl: this.currentData.kypcsl, tyxysl: this.currentData.tyxysl, yyhsl: this.currentData.yyhsl, }).then(ele => {
              this.cyfx = ele.result.cyfx
              this.tipContentList = ele.result.tipContentList
            })
          }
        } else {
          this.$message.error(res.resultDesc)
        }
      })
    }
      }
     
  },
  methods: {
    initialization() {
      getStudyAnalysis({ xsid: this.$store.state.userInfo.yhid, kssj: 1, jssj: 1 }).then(res => {
        if (res.success) {
          this.currentData = res.result
          this.currentData.listStudy.forEach(ele => {
            ele.total = ele.scsl + ele.jscsl + ele.shsl
            if (ele.scsl || ele.shsl || ele.jscsl) {
              ele['生词'] = Math.floor(ele.scsl / ele.total * 10000) / 100
              ele['熟词'] = Math.floor(ele.shsl / ele.total * 10000) / 100
              ele['夹生词'] = Math.floor(ele.jscsl / ele.total * 10000) / 100
            }
          })
          this.setgauge()
          this.setcategory()
          this.setPieone()
          this.setPietwo()
          this.setbroke()
          this.setradar()
          if (this.currentData.tyxysl + this.currentData.yyhsl + this.currentData.hyysl + this.currentData.kypcsl <= 0) {
            this.cyfx = '暂无错因'
            this.tipContentList = ['加油😄']
          } else if (this.currentData.tyxysl + this.currentData.yyhsl + this.currentData.hyysl + this.currentData.kypcsl === 400) {
            this.cyfx = '暂无错因'
            this.tipContentList = ['继续努力😄']
          } else {
            getStudytips({ hyysl: this.currentData.hyysl, kypcsl: this.currentData.kypcsl, tyxysl: this.currentData.tyxysl, yyhsl: this.currentData.yyhsl, }).then(ele => {
              this.cyfx = ele.result.cyfx
              this.tipContentList = ele.result.tipContentList
            })
          }
        } else {
          this.$message.error(res.resultDesc)
        }
      })
    },
    //  ------------------------------------------------------折线图
    setbroke() {
      // 获取echarts Dom
      var myEcharts = echarts.init(document.getElementById('broke'))
      // setOption设置echarts配置数据
      this.prokeOption.xAxis.data = this.currentData.rqList
      this.prokeOption.series[0].data = this.currentData.mrdcsList
      this.prokeOption.dataZoom[0].end = this.currentData.mrdcsList.length > 12 ? 100 / (this.currentData.mrdcsList.length / 10) : 100
      myEcharts.setOption(this.prokeOption)
      window.addEventListener("resize", function () {
        myEcharts.resize();
      });
    },
    //  ------------------------------------------------------柱状图
    setcategory() {
      var myEcharts = echarts.init(document.getElementById('category'))
      this.categoryOption.dataset.source = this.currentData.listStudy
      this.categoryOption.dataZoom[0].end = this.currentData.listStudy.length > 12 ? 100 / (this.currentData.listStudy.length / 10) : 100
      myEcharts.setOption(this.categoryOption)
      window.addEventListener("resize", function () {
        myEcharts.resize();
      });
    },
    //  ------------------------------------------------------雷达图
    setradar() {
      var myEcharts = echarts.init(document.getElementById('radar'))
      this.radarOption.series[0].data[0].value = [this.currentData.zhsl, this.currentData.tyxysl, this.currentData.yyhsl, this.currentData.kypcsl, this.currentData.hyysl]
      myEcharts.setOption(this.radarOption)
      window.addEventListener("resize", function () {
        myEcharts.resize();
      });
    },
    //  -----------------------------------------------------饼图1
    setPieone() {
      var myEcharts = echarts.init(document.getElementById('canvas1'))
      this.pieOption1.series[0].data[0].value = this.currentData.shcsl ? this.currentData.shcsl : 0
      this.pieOption1.series[0].data[1].value = this.currentData.jscsl ? this.currentData.jscsl : 0
      this.pieOption1.series[0].data[2].value = this.currentData.scsl ? this.currentData.scsl : 0
      myEcharts.setOption(this.pieOption1)
      window.addEventListener("resize", function () {
        myEcharts.resize();
      })
    },
    //  -----------------------------------------------------饼图2
    setPietwo() {
      var myEcharts = echarts.init(document.getElementById('canvas2'))
      this.pieOption2.series[0].data[0].value = this.currentData.wzwsl ? this.currentData.wzwsl : 0
      this.pieOption2.series[0].data[1].value = this.currentData.yzwsl ? this.currentData.yzwsl : 0
      myEcharts.setOption(this.pieOption2)
      window.addEventListener("resize", function () {
        myEcharts.resize();
      });
    },
    //  -----------------------------------------------------仪表盘
    setgauge() {
      var myEcharts = echarts.init(document.getElementById('gauge'))
      this.gaugeOption.series[0].data[0].value = this.currentData.ztqk / 100
      myEcharts.setOption(this.gaugeOption)
      window.addEventListener("resize", function () {
        myEcharts.resize();
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.secharts {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.header {
  width: 100%;
  height: 90px;
  box-shadow: 0 2px 4px 2px rgba(89, 144, 255, 0.2);
}
main {
  width: 100%;
  height: calc(100% - 140px);
  box-sizing: border-box;
  padding: 0.8vw 2vw;
  .select {
    height: 2.6vw;
    width: 47vw;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 0 4px 4px rgba(89, 144, 255, 0.2);
    position: relative;
    background-color: rgb(109, 214, 255);
    /deep/ .el-input__icon {
      height: 0;
    }
    .icon {
      width: 1.8vw;
      height: 1.8vw;
      position: absolute;
      top: 50%;
      left: 3%;
      transform: translate(-50%, -50%);
      z-index: 3;
      padding-right: 8px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 2vw);
    .box {
      width: 49%;
      height: 46%;
      float: left;
      border-radius: 10px;
      margin-right: 2%;
      margin-top: 1%;
      box-shadow: 0 1px 5px 2px rgba(89, 144, 255, 0.2);
      .title {
        width: 95%;
        height: 11%;
        font-size: 1vw;
        margin: auto;
        border-bottom: 2px solid #eee;
        position: relative;
        margin-bottom: 2%;
        span {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      .images {
        width: 90%;
        height: 35%;
        box-sizing: border-box;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        .img1 {
          background: url("../../../assets/images/student/timeback.png")
            no-repeat;
        }
        .img2 {
          background: url("../../../assets/images/student/numback.png")
            no-repeat;
        }
        .img3 {
          background: url("../../../assets/images/student/gradeback.png")
            no-repeat;
          p {
            float: left;
            width: 50% !important;
          }
        }
        .img {
          width: 28%;
          height: 100%;
          background-size: 100% 100%;
          p {
            width: 100%;
            height: 50%;
            font-size: 1vw;
            color: #fff;
            box-sizing: border-box;
            padding-left: 10%;
            position: relative;
            span {
              position: absolute;
              top: 50%;
              transform: translateY(-30%);
            }
          }
          p:nth-child(2) {
            height: 40%;
            font-size: 1.1vw;
            span {
              top: 0;
              transform: translateY(0);
            }
          }
        }
      }
      .canvas {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 2% 0;
        .canvas1 {
          width: 50%;
          height: 100%;
          float: left;
        }
        .canvas2 {
          width: 50%;
          height: 100%;
          float: left;
        }
      }
    }
    .box:nth-child(even) {
      margin-right: 0;
    }
  }
}
.broke {
  width: 100%;
  height: 80%;
}
.category {
  width: 100%;
  height: 80%;
}
.echartsBox {
  width: 100%;
  height: 100%;
  .tips {
    width: 50%;
    height: 75%;
    box-sizing: border-box;
    padding: 0 3% 0 0;
    float: left;
    overflow-y: auto;
    .top {
      .blod {
        font-size: 1vw;
        color: #333;
        font-weight: 600;
      }
      .text {
        font-size: 0.9vw;
        color: #666;
        margin-top: 0.3vw;
      }
    }
    .bottom {
      margin-top: 0.5vw;
      .text {
        margin-top: 0.3vw;
      }
    }
  }
  .radar {
    width: 50%;
    height: 80%;
    float: left;
  }
}
.gauge {
  width: 50%;
  height: 100%;
  float: right;
  box-sizing: border-box;
  padding-top: 13%;
}
.selsectbox {
  width: 130px;
  background: #fff;
  border-radius: 4px;
}
//自定义el-input框的一些自定义样式
/deep/ .selsectbox .el-input--small .el-input__inner {
  // background-color: #e6f0ff;
  padding-left: 40px;
  height: 50px;
  border-radius: 5px;
  // color: #1890FF;
  font-size: 18px;
  border: 1px solid #1890ff;
  font-weight: 600;
}
// //自定义左边图标
// .selsectbox /deep/.el-input__inner{
//   background: url('../../../assets/images/student/left.png') no-repeat center center; //引入icon
//   background-size: 18px 18px;  //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
//   background-position:0.5vw 50%; //在input中定位置  可以调整自定义icon的左右上下位置
//   padding: 0 0 0 2.5vw; //需要设置padding 把placeholder向右移
//   box-sizing: border-box;
//   font-size: 14px;
// }
//替换右边边默认图标
/deep/.selsectbox .el-icon-arrow-up::before {
  content: "";
  background: url("../../../assets/images/student/triangle.png") center center
    no-repeat;
  position: absolute;
  background-size: 18px 12px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
}
/deep/ .selsectbox .el-input--small input::-webkit-input-placeholder {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 550;
  // color: #2881FF;
}
</style>
<style lang="scss">
.secharts {
  .el-input__inner {
    height: 2vw;
    line-height: 0;
  }
  .el-input__icon {
    line-height: inherit;
    width: 2vw;
  }
  .el-input__suffix-inner {
    display: block;
    height: 100%;
    // padding-top: .3vw;
  }
  // .radar{
  // canvas{
  // z-index: 2002 !important;
  // }
  // }
  .el-range-separator {
    line-height: 1.5vw;
  }
  .el-date-editor .el-range__icon {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #87c2ef;
    border-radius: 20px;
  }
}
</style>